import CVSectionHeader from '../cv-section-header/CVSectionHeader';
import styles from './CVPersonal.module.css';

function CVPersonal() {
    return (
        <div className={styles['cv-personal']}>
            <CVSectionHeader title='Personal' />

            <p className={styles['cv-personal-info']}>
                40 years old, married with 2 kids, love walking (for hours), meditation, and reading
                books of all genres.
            </p>
        </div>
    );
}

export default CVPersonal;
