import CVSectionHeader from '../cv-section-header/CVSectionHeader';
import styles from './CVEducation.module.css';

function CVEducation() {
    return (
        <div className={styles['cv-education']}>
            <CVSectionHeader title='Education' />

            <div className={styles['cv-education-degree']}>
                <h1 className={styles['cv-education-degree-title']}>
                    Masters Degree in Business Administration (MBA)
                </h1>
                <p className={styles['cv-education-degree-details']}>
                    -&nbsp;&nbsp;&nbsp;Ain-Shams University, Faculty of Business, expected on March
                    2025.
                </p>
                <p className={styles['cv-education-degree-details']}>
                    -&nbsp;&nbsp;&nbsp;Finance and Investment specialty.
                </p>
                <br />
                <h1 className={styles['cv-education-degree-title']}>Bachelor Degree (B.Sc.)</h1>
                <p className={styles['cv-education-degree-details']}>
                    -&nbsp;&nbsp;&nbsp;Ain-Shams University, Electrical Engineering, 2006, with
                    grade of Excellent with Honor.
                </p>
            </div>
        </div>
    );
}

export default CVEducation;
